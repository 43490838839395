<template>
  <a-table :columns="columns" :data-source="data">

    <template #status="{ text: status }">
      <span>
        <a-tag :color=" typeof status !== 'undefined' ? status ? 'green' : 'red' : 'white'">
          {{ typeof status !== "undefined" ? status ? 'AKTİF' : 'AKTİF DEĞİL' : null }}
        </a-tag>
      </span>
    </template>

    <template #tPrice="data">
      <span>{{ typeof data.text !== "undefined" ? data.text + '₺' : null }}</span>
    </template>

    <template #price="data">
      <span>{{ data.text }}₺</span>
    </template>

    <template #discountPrice="data">
      <span>
      <a-tag :color="typeof data.text !== 'undefined' ? 'green' : 'white'">
        <span class="flex-center" style="font-size: 17px">
          {{ typeof data.text !== 'undefined' ? data.text + '₺' : null }}
        </span>
      </a-tag>
      </span>
    </template>

    <template #payment="{ text: payment }">
      <span>
        <a-tag :color=" typeof payment !== 'undefined' ? payment ? 'green' : 'red' : 'white'">
          {{ typeof payment !== "undefined" ? payment ? 'ÖDENDİ' : 'ÖDEME BEKLİYOR' : null }}
        </a-tag>
      </span>
    </template>

    <template #createdAt="{ text: date }">
      <span>
        {{ typeof date !== "undefined" ? unixToDate(date) : null }}
      </span>
    </template>

    <template #paymentAt="{ text: date }">
      <span>
        {{ typeof date !== "undefined" ? unixToDate(date) : null }}
      </span>
    </template>

    <template #process="{ record }">
      <a-button v-if="typeof record.children !== 'undefined'" type="primary" @click="addModalBtn(record)">Ekle
      </a-button>
    </template>
  </a-table>

  <add-catalog-modal @refreshTable="$emit('refreshTable')" :customer="customer" :catalogKey="catalogKey"
                     :modalVisible="addCatalogModalVisible" @closeModal="addCatalogModalVisible = $event"/>
  <!-- :row-selection="rowSelection" -->
</template>
<script>
import {defineComponent} from "vue";
import AddCatalogModal from "@/views/Customers/components/CatalogListTable/AddCatalogModal";
import {unixToDate} from "@/helpers/unixToDate"

const columns = [
  {
    title: 'Katalog & Servis Adı',
    dataIndex: 'name',
    key: 'key',
  },
  {
    title: 'Toplam Fiyat',
    dataIndex: 'tPrice',
    slots: {
      customRender: "tPrice"
    }
  },
  {
    title: 'Liste Fiyatı',
    dataIndex: 'price',
    slots: {
      customRender: "price"
    }
  },
  {
    title: 'İndirimli Fiyatı',
    dataIndex: 'discountPrice',
    slots: {
      customRender: "discountPrice"
    }
  },
  {
    title: 'Durumu',
    dataIndex: 'status',
    slots: {
      customRender: 'status'
    },
  },
  {
    title: 'Ödeme',
    dataIndex: 'payment',
    slots: {
      customRender: 'payment'
    },
  },
  {
    title: 'Oluşturulma Tarihi',
    dataIndex: 'createdAt',
    slots: {
      customRender: 'createdAt'
    },
  },
  {
    title: 'Ödeme Tarihi',
    dataIndex: 'paymentAt',
    slots: {
      customRender: 'paymentAt'
    },
  },
  {
    title: 'İşlem',
    dataIndex: 'children',
    slots: {
      customRender: 'process'
    },
  },
];
const data = [];
export default defineComponent({
  data() {
    return {
      addCatalogModalVisible: false,
      catalogKey: null,
      unixToDate
    }
  },
  components: {
    AddCatalogModal
  },
  props: ["customer"],
  setup() {
    return {
      data,
      columns
    };
  },
  methods: {
    tableUpdate() {

      let data = this.customer.catalogs
      this.data = []

      if (typeof data !== "undefined")
        data.forEach(catalog => {

          this.data.push({
            key: catalog.key,
            name: catalog.name + " Kataloğu",
            price: catalog.catalogPrice,
            tPrice: catalog.totalPrice,
            children: catalog.services
          })

        })
    },
    addModalBtn(catalog) {
      this.addCatalogModalVisible = true
      this.catalogKey = catalog.key
    }
  },
  created() {
    this.tableUpdate();
  },
  beforeUpdate() {
    this.tableUpdate();
  }
});
</script>

<style scoped>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>