<template>
  <a-modal :visible="modalVisible" width="1150px" title="Kataloğa Hizmet Ekle" @ok="closeModal" @cancel="closeModal">

    <div class="serviceSelectArea">
      <div class="addArea">
        <h3>Eklemek istediğiniz hizmeti seçin</h3>
        <div class="addBtnArea">
          <a-button class="addBtn" @click="addMarketplaceModalVisible = true" type="primary">
            <PlusOutlined/>
            Pazaryeri Ekle
          </a-button>
          <a-button class="addBtn" @click="addMarketingModalVisible = true" type="primary">
            <PlusOutlined/>
            Marketing Ekle
          </a-button>
          <a-button class="addBtn" @click="addXMLModalVisible = true" type="primary">
            <PlusOutlined/>
            XML Ekle
          </a-button>
          <a-button class="addBtn" @click="addSupplierModalVisible = true" type="primary">
            <PlusOutlined/>
            Tedarikçi Ekle
          </a-button>
        </div>
      </div>

      <div class="resultArea">
        <a-table :dataSource="dataSource" :columns="columns">
          <template #name="data"><span> {{ data.text.toUpperCase() }} </span></template>

          <template #payment="data"><span> {{
              data.text.paymentLoop !== "" ? data.text.paymentLoop.replace('monthly', 'AYLIK').replace('yearly', 'YILLIK') : "-"
            }} </span></template>

          <template #loop="data"><span> {{
              data.text.updateLoop !== "" ? data.text.updateLoop.replace('hours', ' SAAT').replace('minutes', ' DAKIKA') : "-"
            }} </span></template>

          <template #type="data"><span> {{
              typeof data.text.updateType !== "undefined" ? data.text.updateType === "1" ? "OTOMATİK" : "MANUEL" : "-"
            }} </span></template>

          <template #price="data"><span> {{ data.text }}₺ </span></template>
          <template #discountPrice="data"><span> {{ typeof data.text !== 'undefined' ? data.text + '₺' : "-" }} </span></template>

          <template #process="data">
            <a-button @click="editService(data)" type="primary" style="margin-right: 10px">
              <EditOutlined/>
              Fiyat Düzenle
            </a-button>
            <a-button @click="removeService(data.text)" type="danger">
              <DeleteOutlined/>
              Sil
            </a-button>
          </template>

        </a-table>
      </div>

      <div class="modals">
        <add-marketplace-modal @addMarketplace="addCatalogItem" :catalogsServices="catalogsServices"
                               :catalogsRefreshSettings="catalogsRefreshSettings" :visible="addMarketplaceModalVisible"
                               @closeModal="addMarketplaceModalVisible = $event"/>
        <add-marketing-modal @addMarketing="addCatalogItem" :catalogsServices="catalogsServices"
                             :catalogsRefreshSettings="catalogsRefreshSettings" :visible="addMarketingModalVisible"
                             @closeModal="addMarketingModalVisible = $event"/>
        <add-x-m-l-modal @addXML="addCatalogItem" :catalogsServices="catalogsServices"
                         :catalogsRefreshSettings="catalogsRefreshSettings" :visible="addXMLModalVisible"
                         @closeModal="addXMLModalVisible = $event"/>
        <add-supplier-modal @addSupplier="addCatalogItem" :catalogsServices="catalogsServices"
                            :catalogsRefreshSettings="catalogsRefreshSettings" :visible="addSupplierModalVisible"
                            @closeModal="addSupplierModalVisible = $event"/>
      </div>


    </div>


    <template #footer>
      <a-button key="back" @click="closeModal">Vazgeç</a-button>
      <a-button key="submit" type="primary" @click="saveData" :disabled="saveDataBtn">Kaydet</a-button>
    </template>
  </a-modal>
  <edit-price-modal @catalogPrice="editService" @closeModal="editPriceModalVisible = $event" :editPriceModalVisible="editPriceModalVisible" :edit-catalog-data="editServiceData"/>
</template>

<script>
import instance from "@/http/axios";
import {PlusOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons-vue";
import editPriceModal from "@/components/editPriceModal";
import uuid from "uuid";
import { sendNotification } from "@/helpers/sendNotification";

/* addModal Component */
import addMarketplaceModal from "@/components/addModals/addMarketplaceModal";
import addMarketingModal from "@/components/addModals/addMarketingModal";
import addXMLModal from "@/components/addModals/addXMLModal";
import addSupplierModal from "@/components/addModals/addSupplierModal";
import {message} from "ant-design-vue";
import {socket} from "@/http/socket";
/* addModal Component end */

export default {
  data() {
    return {
      catalogs: null,
      catalogsServices: null,
      catalogsRefreshSettings: null,

      editPriceModalVisible: false,
      editServiceData: null,

      dataSource: [],
      columns: [
        {
          title: 'Servis Adı',
          dataIndex: 'name',
          key: 'name',
          slots: {
            customRender: 'name'
          },
        },
        {
          title: 'Ödeme Döngüsü',
          dataIndex: 'options',
          key: 'options',
          slots: {
            customRender: 'payment'
          },
        },
        {
          title: 'Güncelleme Sıklığı',
          dataIndex: 'options',
          key: 'options',
          slots: {
            customRender: 'loop'
          },
        },
        {
          title: 'Güncelleme Türü',
          dataIndex: 'options',
          key: 'options',
          slots: {
            customRender: 'type'
          },
        },
        {
          title: 'Liste Fiyatı',
          dataIndex: 'price',
          key: 'price',
          slots: {
            customRender: 'price'
          },
        },
        {
          title: 'İndirimli Fiyat',
          dataIndex: 'discountPrice',
          key: 'discountPrice',
          slots: {
            customRender: 'discountPrice'
          },
        },
        {
          title: 'İşlem',
          dataIndex: 'key',
          key: 'key',
          slots: {
            customRender: 'process'
          },
        },
      ],
      /* addModal Visible */
      addMarketplaceModalVisible: false,
      addMarketingModalVisible: false,
      addXMLModalVisible: false,
      addSupplierModalVisible: false,
    }
  },
  components: {
    PlusOutlined, DeleteOutlined, EditOutlined,
    addMarketplaceModal, addMarketingModal, addXMLModal, addSupplierModal, editPriceModal
  },
  computed: {
    saveDataBtn() {
      return this.dataSource.length === 0
    }
  },
  props: ["modalVisible", "catalogKey", "customer"],
  methods: {
    closeModal() {
      this.dataSource = []
      this.$emit('closeModal', false)
    },
    removeService(key) {
      let item = this.dataSource.find(data => data.key === key)
      let index = this.dataSource.indexOf(item)
      if (index > -1)
        this.dataSource.splice(index, 1)
    },
    editService(item, event) {
      let service = this.dataSource.find(service => service.key === (item.text ?? item.key))
      let index = this.dataSource.indexOf(service)

      if (typeof event === "undefined") {
        this.editPriceModalVisible = true
        this.editServiceData = item.record
      } else {
        this.dataSource[index].discountPrice = parseInt(event)
      }

    },
    saveData: function () {

      let catalog = this.customer.catalogs.find(catalog => {
        return catalog.key === this.catalogKey
      })
      let index = this.customer.catalogs.indexOf(catalog)

      let formData = new FormData()
      formData.append('services', JSON.stringify(this.dataSource))
      formData.append('key', this.catalogKey)
      formData.append('index', index)
      formData.append('oid',this.customer._id['$oid'])

      instance.post('/customer/catalog/addService', formData).then(response => {
        if (response.data.code === 201) {
          message.success(response.data.msg)
          sendNotification('system','department','accouting','info','Yeni bir servis eklendi!',this.customer.name + " isimli müşterinin kataloğuna "+ this.$store.getters.authUserInfo.name +" tarafından yeni bir servis eklendi")
          return true;
        } else {
          message.error(response.data.msg)
          return false;
        }
      }).then(status => {
        this.$emit('refreshTable', true)

        if (status)
          this.closeModal()
      })

    },
    addCatalogItem(data) {
      this.dataSource.push(data)
    }
  },
  beforeMount() {
    instance.get('/catalog/get').then(response => {
      this.catalogs = response.data.catalogs.catalogs
      this.catalogsServices = response.data.catalogs_services.catalogs_services
      this.catalogsRefreshSettings = response.data.catalogs_services_refresh_settings.catalogs_services_refresh_settings
    })
  }
};
</script>

<style scoped>
.addArea, .addBtnArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addArea {
  flex-direction: column;
}

.addBtn {
  margin-right: 5px;
}

.resultArea {
  margin-top: 30px;
}
</style>