<template>
  <div>
    <a-modal :visible="this.editPriceModalVisible" :title="catalogData.name + ' Fiyatı Düzenle'"
             @ok="closeModal" @cancel="closeModal">

      <a-form layout="horizontal">
        <a-form-item label="Şuanki Fiyat">
          <a-input :value="typeof catalogData.totalPrice !== 'undefined' ? catalogData.totalPrice : catalogData.price" disabled=""/>
        </a-form-item>
        <a-form-item label="Yeni Fiyat">
          <a-input type="number" v-model:value="price" placeholder="yeni fiyatı girin"/>
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button key="back" @click="closeModal">Vazgeç</a-button>
        <a-button key="submit" type="primary" @click="savePrice()">Kaydet</a-button>
      </template>

    </a-modal>
  </div>
</template>
<script>
import {defineComponent} from 'vue';

export default defineComponent({
  data() {
    return {
      catalogData: {
        name: "",
        totalPrice: 0,
        price: 0
      },
      price: 0
    }
  },
  props: ["editPriceModalVisible", "editCatalogData"],
  methods: {
    closeModal() {
      this.$emit('closeModal', false)
      this.price = 0
    },
    savePrice() {
      this.$emit('catalogPrice', this.catalogData, this.price)
      this.closeModal()
    }
  },
  watch: {
    editPriceModalVisible() {
      if (this.editCatalogData !== null)
        this.catalogData = this.editCatalogData
    }
  }
});
</script>