<template>
  <Default>
    <template v-slot:breadcrumb>
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>Anasayfa</a-breadcrumb-item>
        <a-breadcrumb-item>Müşterilerim</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <template v-slot:content>
      <div class="content">
        <div class="content-head">
          <h3>Atanmış Müşterilerim</h3>
          <a-button type="primary" @click="openModal">
            Müşterileri Listele
          </a-button>
        </div>
        <Table />
      </div>
      <Modal
        @modalVisible="modalVisible = $event"
        :modalVisible="modalVisible"
      />
    </template>
    <template v-slot:mTitle>Müşterilerim | Bill</template>
  </Default>
</template>

<script>
import Default from "@/components/_layouts/Default";
import Modal from "./components/CustomersModal";
import Table from "./components/AssignedCustomerListTable";

export default {
  name: "Home",
  components: {
    Default,
    Modal,
    Table
  },
  data() {
    return {
      modalVisible: false
    };
  },
  methods: {
    openModal() {
      this.modalVisible = true;
    }
  }
};
</script>

<style scoped>
.content {
  padding: 24px;
  background: #fff;
  min-height: 360px;
}

.content-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>
