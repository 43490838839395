<template>
  <a-modal :visible="modalVisible" width="1200px" title="Yeni Katalog Ekle" @ok="closeModal" @cancel="closeModal">

    <div class="catalogSelectArea">
      <label>Katalog Seçimi Yapın
        <a-select
            v-model:value="selectValue"
            style="width: 240px"
            ref="select"
            @change="selectChange"
            :disabled="selectDisabled"
        >
          <a-select-option value="-1">-- Katalog Seçimi Yapın --</a-select-option>
          <a-select-option v-for="catalog in catalogs" :value="catalog.id">{{ catalog.name }}</a-select-option>
        </a-select>
        <a-button v-if="selectDisabled" type="primary" @click="selectDisabled = false" style="margin-left: 5px"> Seçimi
          Yenile
        </a-button>
      </label>
    </div>

    <div class="serviceSelectArea" v-if="selectDisabled">
      <a-divider style="border-color: darkblue" dashed/>


      <div class="addArea">
        <h3>Eklemek istediğiniz hizmeti seçin</h3>
        <div class="addBtnArea">
          <a-button class="addBtn" @click="addMarketplaceModalVisible = true" type="primary">
            <PlusOutlined/>
            Pazaryeri Ekle
          </a-button>
          <a-button class="addBtn" @click="addMarketingModalVisible = true" type="primary">
            <PlusOutlined/>
            Marketing Ekle
          </a-button>
          <a-button class="addBtn" @click="addXMLModalVisible = true" type="primary">
            <PlusOutlined/>
            XML Ekle
          </a-button>
          <a-button class="addBtn" @click="addSupplierModalVisible = true" type="primary">
            <PlusOutlined/>
            Tedarikçi Ekle
          </a-button>
        </div>
      </div>

      <div class="resultArea">
        <a-table :dataSource="dataSource" :columns="columns">
          <template #name="data"><span> {{ data.text.toUpperCase() }} </span></template>

          <template #payment="data"><span> {{
              data.text.paymentLoop !== "" ? data.text.paymentLoop.replace('monthly', 'AYLIK').replace('yearly', 'YILLIK') : "-"
            }} </span></template>

          <template #loop="data"><span> {{
              data.text.updateLoop !== "" ? data.text.updateLoop.replace('hours', ' SAAT').replace('minutes', ' DAKIKA') : "-"
            }} </span></template>

          <template #type="data"><span> {{
              typeof data.text.updateType !== "undefined" ? data.text.updateType === "1" ? "OTOMATİK" : "MANUEL" : "-"
            }} </span></template>

          <template #price="data"><span> {{ data.text }}₺ </span></template>
          <template #discountPrice="data"><span> {{ typeof data.text !== 'undefined' ? data.text + '₺' : "-" }} </span></template>

          <template #process="data">
            <a-button @click="editService(data)" type="primary" style="margin-right: 10px">
              <EditOutlined/>
              Fiyat Düzenle
            </a-button>
            <a-button @click="removeService(data.text)" type="danger">
              <DeleteOutlined/>
              Sil
            </a-button>
          </template>

        </a-table>
      </div>

      <div class="modals">
        <add-marketplace-modal @addMarketplace="addCatalogItem" :catalogsServices="catalogsServices"
                               :catalogsRefreshSettings="catalogsRefreshSettings" :visible="addMarketplaceModalVisible"
                               @closeModal="addMarketplaceModalVisible = $event"/>
        <add-marketing-modal @addMarketing="addCatalogItem" :catalogsServices="catalogsServices"
                             :catalogsRefreshSettings="catalogsRefreshSettings" :visible="addMarketingModalVisible"
                             @closeModal="addMarketingModalVisible = $event"/>
        <add-x-m-l-modal @addXML="addCatalogItem" :catalogsServices="catalogsServices"
                         :catalogsRefreshSettings="catalogsRefreshSettings" :visible="addXMLModalVisible"
                         @closeModal="addXMLModalVisible = $event"/>
        <add-supplier-modal @addSupplier="addCatalogItem" :catalogsServices="catalogsServices"
                            :catalogsRefreshSettings="catalogsRefreshSettings" :visible="addSupplierModalVisible"
                            @closeModal="addSupplierModalVisible = $event"/>
      </div>

    </div>

    <template #footer>
      <a-button key="back" @click="closeModal">Vazgeç</a-button>
      <a-button key="submit" type="primary" @click="saveData" :disabled="saveDataBtn">Kaydet</a-button>
    </template>
  </a-modal>

  <edit-price-modal @catalogPrice="editService" @closeModal="editPriceModalVisible = $event" :editPriceModalVisible="editPriceModalVisible" :edit-catalog-data="editServiceData"/>
</template>

<script>
import instance from "@/http/axios";
import {PlusOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons-vue";
import uuid from "uuid"

import editPriceModal from "@/components/editPriceModal";

/* addModal Component */
import addMarketplaceModal from "@/components/addModals/addMarketplaceModal";
import addMarketingModal from "@/components/addModals/addMarketingModal";
import addXMLModal from "@/components/addModals/addXMLModal";
import addSupplierModal from "@/components/addModals/addSupplierModal";
/* addModal Component end */

export default {
  data() {
    return {
      selectValue: "-1",
      selectDisabled: false,

      catalogs: null,
      catalogsServices: null,
      catalogsRefreshSettings: null,

      editPriceModalVisible: false,
      editServiceData: null,

      dataSource: [],
      columns: [
        {
          title: 'Servis Adı',
          dataIndex: 'name',
          key: 'name',
          slots: {
            customRender: 'name'
          },
        },
        {
          title: 'Ödeme Döngüsü',
          dataIndex: 'options',
          key: 'options',
          slots: {
            customRender: 'payment'
          },
        },
        {
          title: 'Güncelleme Sıklığı',
          dataIndex: 'options',
          key: 'options',
          slots: {
            customRender: 'loop'
          },
        },
        {
          title: 'Güncelleme Türü',
          dataIndex: 'options',
          key: 'options',
          slots: {
            customRender: 'type'
          },
        },
        {
          title: 'Liste Fiyatı',
          dataIndex: 'price',
          key: 'price',
          slots: {
            customRender: 'price'
          },
        },
        {
          title: 'İndirimli Fiyat',
          dataIndex: 'discountPrice',
          key: 'discountPrice',
          slots: {
            customRender: 'discountPrice'
          },
        },
        {
          title: 'İşlem',
          dataIndex: 'key',
          key: 'key',
          slots: {
            customRender: 'process'
          },
        },
      ],
      /* addModal Visible */
      addMarketplaceModalVisible: false,
      addMarketingModalVisible: false,
      addXMLModalVisible: false,
      addSupplierModalVisible: false,
    }
  },
  components: {
    PlusOutlined, DeleteOutlined, EditOutlined,
    addMarketplaceModal, addMarketingModal, addXMLModal, addSupplierModal, editPriceModal
  },
  props: ["modalVisible"],
  computed: {
    saveDataBtn() {
      return this.dataSource.length === 0
    }
  },
  methods: {
    closeModal() {
      this.dataSource = []
      this.selectValue = "-1"
      this.selectDisabled = false
      this.$emit('closeModal', false)
    },
    removeService(key) {
      let item = this.dataSource.find(data => data.key === key)
      let index = this.dataSource.indexOf(item)
      if (index > -1)
        this.dataSource.splice(index, 1)
    },
    editService(item, event) {
      let service = this.dataSource.find(service => service.key === (item.text ?? item.key))
      let index = this.dataSource.indexOf(service)

      if (typeof event === "undefined") {
        this.editPriceModalVisible = true
        this.editServiceData = item.record
      } else {
        this.dataSource[index].discountPrice = parseInt(event)
      }

    },
    selectChange(selected) {
      if (selected !== "-1") {
        this.selectDisabled = true
        this.dataSource = []
      }
    },
    saveData: function () {
      let selectedCatalogData = this.catalogs.find(catalog => catalog.id === this.selectValue)

      let servicePrice = 0;
      this.dataSource.forEach((v, k) => {
        servicePrice += typeof v.discountPrice !== 'undefined' ? parseInt(v.discountPrice) : parseInt(v.price)
      })

      let saveCatalog = {
        id: this.selectValue,
        key: uuid.v4(),
        name: this.selectValue.toUpperCase(),
        catalogPrice: parseInt(selectedCatalogData.price),
        totalPrice: parseInt(selectedCatalogData.price) + parseInt(servicePrice),
        services: this.dataSource
      }

      this.$emit('addCatalog', saveCatalog)
      this.closeModal()
    },
    addCatalogItem(data) {
      this.dataSource.push(data)
    }
  },
  beforeMount() {
    instance.get('/catalog/get').then(response => {
      this.catalogs = response.data.catalogs.catalogs
      this.catalogsServices = response.data.catalogs_services.catalogs_services
      this.catalogsRefreshSettings = response.data.catalogs_services_refresh_settings.catalogs_services_refresh_settings
    })
  }
};
</script>

<style scoped>
.addArea, .addBtnArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addArea {
  flex-direction: column;
}

.addBtn {
  margin-right: 5px;
}

.resultArea {
  margin-top: 30px;
}
</style>