<template>
  <div>
    <a-modal
      :visible="modalVisible"
      title="Atanmamış Müşteriler"
      @cancel="closeModal"
      width="1200px"
      :footer="null"
      style="top: 20px"
    >
      <CustomerListTable />
    </a-modal>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import CustomerListTable from "./CustomerListTable.vue";

export default {
  props: ['modalVisible'],
  components: {
    CustomerListTable
  },
  methods: {
    closeModal(){
      this.$emit('modalVisible',false)
    }
  }
};
/* eslint-enable */
</script>
