<template>
  <a-table
      :columns="columns"
      :row-key="record => record.cp_id"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
  >
    <template
        #inputSearch="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
    >
      <div style="padding: 8px">
        <a-input
            :ref="c => (nameSearchTextInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          <template #icon>
            <SearchOutlined/>
          </template>
          Search
        </a-button>
        <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
        >
          Reset
        </a-button>
      </div>
    </template>
    <template #filterIcon="filtered">
      <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
    </template>

    <template #name="{ text }"> {{ text }}</template>
    <template #business="{ text }"> {{ text }}</template>
    <template #email="{ email }"> {{ email }}</template>
    <template #phones="{ phone }"> {{ phone }}</template>

    <template #action="{ record }">
      <a-button type="primary" @click="customerDetail(record._id['$oid'])"
      >Detay
      </a-button>
    </template>
  </a-table>

  <CustomerDetailDrawer
      @visibleChanged="visible = $event"
      :drawerVisibility="visible"
      :assigned="true"
      @refreshCustomerTable="fetch"
  />
</template>
<script>
import instance from "@/http/axios";
import {SearchOutlined} from "@ant-design/icons-vue";
import CustomerDetailDrawer from "@/views/Customers/components/CustomerDetailDrawer";

const columns = [
  {
    title: "Temsilci",
    dataIndex: "agents",
    sorter: true,
    width: "20%",
    slots: {
      customRender: "agents",
      filterDropdown: "inputSearch",
      filterIcon: "filterIcon"
    }
  },
  {
    title: "Müşteri Adı",
    dataIndex: "name",
    sorter: true,
    width: "20%",
    slots: {
      customRender: "name",
      filterDropdown: "inputSearch",
      filterIcon: "filterIcon"
    }
  },
  {
    title: "Ticari Ünvan",
    dataIndex: "business",
    sorter: true,
    width: "20%",
    slots: {
      customRender: "business",
      filterDropdown: "inputSearch",
      filterIcon: "filterIcon"
    }
  },
  {
    title: "Müşteri Email",
    dataIndex: "mail",
    sorter: true,
    width: "20%",
    slots: {
      customRender: "mail",
      filterDropdown: "inputSearch",
      filterIcon: "filterIcon"
    }
  },
  {
    title: "Müşteri Telefon",
    dataIndex: "phones",
    width: "20%",
    slots: {
      customRender: "phone",
      filterDropdown: "inputSearch",
      filterIcon: "filterIcon"
    }
  },
  {
    title: "İşlemler",
    fixed: "right",
    slots: {customRender: "action"}
  }
];

export default {
  components: {
    SearchOutlined,
    CustomerDetailDrawer
  },
  data() {
    return {
      data: [],
      params: {},
      visible: false,

      nameSearchText: "",
      nameSearchTextInput: null,
      nameSearchedColumn: "",

      pagination: {},
      loading: false,
      columns
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const pager = {...this.pagination};
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      }, this.nameSearchedColumn, this.nameSearchText);
    },
    fetch(params = {}, searchColumn = null, searchColumnText = null) {
      this.loading = true;
      this.params = params;
      instance
          .get("/customer/get/allAssigned", {
            params: {
              ...params,
              searchColumn,
              searchColumnText
            }
          })
          .then(response => {
            this.data = [];
            return response;
          })
          .then(response => {
            let totalPage = response.data.totalCustomerData;
            const pagination = {...this.pagination};
            pagination.total = totalPage;

            response.data.data.forEach(v => {
              this.data.push({
                _id: {
                  $oid: v._id["$oid"]
                },
                cp_id: v.cp_id,
                name: v.name,
                business: v.businessTitle,
                mail: v.mail[0],
                phones: v.phones[0],
                agents: typeof v.agent !== "undefined" ? v.agent.name : undefined
              });
            });

            this.pagination = pagination;
            this.loading = false;
          });
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();

      this.nameSearchText = selectedKeys[0];
      this.nameSearchedColumn = dataIndex;
      this.fetch(this.params, this.nameSearchedColumn, this.nameSearchText);
      this.$forceUpdate();
    },
    handleReset(clearFilters) {
      clearFilters();
      this.nameSearchText = "";
    },
    customerDetail(customerOid) {
      this.$store.dispatch("callCustomerInformation", customerOid).then(res => {
        if (res) this.visible = true;
      });
    }
  }
};
</script>
