<template>
  <a-modal :visible="visible" width="1000px" title="Yeni Marketing Ekle" @ok="closeModal" @cancel="closeModal">

    <div class="steps-area">
      <div class="steps-step">
        <a-steps direction="vertical" :current="current">
          <a-step title="Marketing Seçimi" description="Kataloğa eklenecek marketing servisi."/>
          <a-step title="Özelliklerin Belirlenmesi" description="Marketing ekstra özellikleri."/>
          <a-step title="Özet" description="Eklenecek verilerin özeti."/>
        </a-steps>
      </div>
      <div class="steps-content">

        <div v-if="steps[current].key === 'step-1'">

          <label class="flex-center">
            Eklenecek Marketing Servisini Seçin
            <a-select
                v-model:value="marketingSelect"
                style="width: 500px;"
                ref="select"
                @change="current = 2"
            >
              <a-select-option value="-1">-- Marketing Servisi Seçin --</a-select-option>
              <a-select-option v-for="services in catalogsServices[1].services" :value="services.id">{{
                  services.name
                }}
              </a-select-option>
            </a-select>
          </label>
        </div>

        <div class="flex-center" v-if="steps[current].key === 'step-2' ">

          <label class="flex-center">
            Ödeme Döngüsü
            <a-select
                v-model:value="paymentLoopSelect"
                style="width: 500px;"
                ref="select"
                :disabled="true"
            >
              <a-select-option value="-1">-- Ödeme Döngüsünü Seçin --</a-select-option>
              <a-select-option value="monthly">Aylık</a-select-option>
              <a-select-option value="yearly">Yıllık</a-select-option>
            </a-select>
          </label>

          <label class="flex-center mt-20">
            Güncelleme Sıklığı
            <a-select
                v-model:value="updateLoopSelect"
                style="width: 500px;"
                ref="select"
                :disabled="true"
            >
              <a-select-option value="-1">-- Güncelleme Sıklığını Seçin --</a-select-option>
              <a-select-option v-for="item in catalogsRefreshSettings" :value="item.id">{{
                  item.name
                }}
              </a-select-option>
            </a-select>
          </label>

        </div>


        <div v-if="steps[current].key === 'step-3'">

          <a-card :title="marketingSelect.toUpperCase() + ' PAZARYERİ DETAYLARI'" style="width: 100%">
            <p>Ödeme Döngüsü: <strong> {{ paymentLoopSelect === "monthly" ? "AYLIK" : "YILLIK" }} </strong></p>
            <p>Güncelleme Sıklığı: <strong> {{ updateLoopSelect.replace('hours',' SAAT') }} </strong></p>
          </a-card>

        </div>

      </div>
    </div>

    <template #footer>
      <a-button v-if="current > 0" style="margin-left: 8px" @click="current = --current" type="default">Önceki
      </a-button>

      <a-button key="back" @click="closeModal">Vazgeç</a-button>
      <a-button key="submit" type="primary" @click="saveData" :disabled="marketingSaveBtn">Kaydet</a-button>
    </template>
  </a-modal>
</template>

<script>
import {ref} from "vue"
import uuid from "uuid"

export default {
  data() {
    return {
      marketingSelect: "-1",
      paymentLoopSelect: "yearly",
      updateLoopSelect: "1hours",
      marketplaceList: null,
      current: ref(0),
      steps: [
        {
          key: 'step-1',
        },
        {
          key: 'step-2',
        },
        {
          key: 'step-3',
        },
      ],
    }
  },
  props: ["visible", "catalogsServices", "catalogsRefreshSettings"],
  methods: {
    closeModal() {
      this.$emit('closeModal', false)
      this.refreshData()
    },
    saveData() {
      let sendData = {
        id: this.marketingSelect,
        key: uuid.v4(),
        name: this.marketingSelect.toUpperCase(),
        status: false,
        payment: false,
        createdAt: new Date().getTime(),
        options: {
          paymentLoop: this.paymentLoopSelect,
          updateLoop: this.updateLoopSelect
        },
        price: ( (this.catalogsServices[1].services.find( services => services.id === this.marketingSelect )).price ) * 12 // sadece yıllık fiyat
      }
      this.$emit('addMarketing', sendData)
      this.closeModal()
    },
    refreshData() {
      this.marketingSelect = "-1"
      this.paymentLoopSelect = "yearly"
      this.updateLoopSelect = "1hours"
      this.current = ref(0)
    },
    stepCompleted(value) {
      if (value !== "-1")
        this.current = ++this.current
    }
  },
  computed: {
    marketingSaveBtn(){
      return !(this.marketingSelect !== "-1")
    }
  }
}
</script>

<style scoped>
.steps-area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.steps-step {
  flex: 1
}

.steps-content {
  flex: 2;
}

.flex-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.mt-20 {
  margin-top: 20px;
}
</style>