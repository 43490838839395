<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1">
      <template #tab>
        <span>
          <AppstoreOutlined/>
          Müşterinin Katalog ve Entegrasyonları
        </span>
      </template>

      <catalog-list-table @refreshTable="$emit('refreshTable')" :customer="customer"/>

    </a-tab-pane>
    <a-tab-pane key="2">
      <template #tab>
        <span>
          <PlusOutlined/>
          Yeni Katalog Ekle
        </span>
      </template>

        <a-card :hoverable="true" style="width: 100%">
          <div class="flex-center" @click="newCatalog">
            <PlusOutlined style="font-size: 46px" />
            <span>Yeni Katalog Ekle</span>
          </div>
        </a-card>

      <NewCatalogModal :modalVisible="modalVisible" @closeModal="modalVisible = $event" @addCatalog="addCatalog"/>

      <div class="mt-20">
        <a-divider style="border-color: darkblue" dashed v-if="catalogs.length !== 0"/>

        <a-row :gutter="[48,16]">
          <a-col :span="8" v-for="item in catalogs">

            <a-card class="ml-10" hoverable :title="item.name + ' KATALOĞU | ' + item.totalPrice + '₺'"
                    style="width: 285px">
              <template class="ant-card-actions" #actions>
<!--                <edit-outlined/>-->
                <span>ÇOK YAKINDA</span>
                <delete-outlined @click="removeCatalog(item)" key="remove"/>
              </template>
              <p v-for="service in item.services">{{ service.name }}</p>
            </a-card>

          </a-col>
<!--          <edit-price-modal :editCatalogData="editCatalogData" :editPriceModalVisible="editPriceModalVisible"-->
<!--                            @closeModal="editPriceModalVisible = $event" @catalogPrice="editTotalPrice"/>-->
        </a-row>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import {PlusOutlined, AppstoreOutlined, SettingOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons-vue";
import {defineComponent, ref} from "vue";
import NewCatalogModal from "@/views/Customers/components/NewCatalogModal";
// import editPriceModal from "@/components/editPriceModal";
import CatalogListTable from "@/views/Customers/components/CatalogListTable/CatalogListTable";

export default defineComponent({
  props: ["catalogs", "customer"],
  data() {
    return {
      modalVisible: false
      // editPriceModalVisible: false,
      // editCatalogData: null
    }
  },
  components: {
    PlusOutlined, DeleteOutlined, AppstoreOutlined, SettingOutlined, EditOutlined,
    NewCatalogModal, /*editPriceModal,*/ CatalogListTable
  },
  setup() {
    return {
      activeKey: ref("1")
    };
  },
  methods: {
    newCatalog() {
      this.modalVisible = true
    },
    addCatalog(data) {
      this.catalogs.push(data)
      this.$store.dispatch('addCatalog', data)
    },
    removeCatalog(item) {
      let remove = this.catalogs.find(catalog => catalog.key === item.key)
      let index = this.catalogs.indexOf(remove)
      this.$store.dispatch('removeCatalog', index)
      if (index > -1)
        this.catalogs.splice(index, 1)
    },
    // editTotalPrice(item, event) {
    //   let catalog = this.catalogs.find(catalog => catalog.key === item.key)
    //   let index = this.catalogs.indexOf(catalog)
    //
    //   if (typeof event === "undefined") {
    //     this.editPriceModalVisible = true
    //     this.editCatalogData = item
    //   } else {
    //     this.catalogs[index].totalPrice = parseInt(event)
    //   }
    // }
  }
});
</script>

<style scoped>
.mt-20 {
  margin-top: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
