<template>
  <a-upload
      v-model:file-list="fileList"
      name="file"
      :multiple="true"
      action="https://api.bill.connectprof.com/file/upload"
      @change="handleChange"
      :headers="headers"
  >
    <a-button>
      <upload-outlined></upload-outlined>
      Yeni Ekle
    </a-button>
  </a-upload>
</template>
<script>
/* eslint-disable */
import {message} from "ant-design-vue";
import {UploadOutlined} from "@ant-design/icons-vue";
import {ref} from "vue";

export default {
  components: {
    UploadOutlined
  },
  data() {
    return {
      fileList: ref([]),
      headers: {
        OID: null,
        UID: this.$store.getters.authUserInfo.id,
        Token: this.$store.getters.authUserInfo.token,
        Authorization: 'Bearer ' + this.$store.getters.authUserInfo.token,
      }
    };
  },
  props: ["customer", "customerFileList"],
  methods: {
    handleChange: function (info) {

      let resFileList = [...info.fileList];

      resFileList = resFileList.map(file => {
        if (file.response) {
          file.url = file.response.url;
        }

        return file;
      });

      this.fileList.value = resFileList;


      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }

      if (info.file.status === "done") {
        message.success(`${info.file.name} isimli dosya başarıyla yüklendi!`);
      } else if (info.file.status === "error") {
        message.error(info.file.response.msg);
      }

      this.$emit('fileListUpdate', this.fileList)
    }
  },
  beforeUpdate() {
    this.headers.OID = this.customer._id["$oid"]
  },
  created() {
    if (this.customer !== null)
      this.headers.OID = this.customer._id["$oid"]
  },
  updated() {
    this.fileList = this.customerFileList
  }
};
</script>