<template>
  <a-drawer
      title="Müşteri Bilgileri"
      :width="1200"
      :closable="true"
      :body-style="{ paddingBottom: '80px' }"
      @close="closeDrawer"
      :visible="drawerVisibility"
      :after-visible-change="visibleChange"
  >
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1">
        <template #tab>
          <span>
            <UserOutlined/>
            Temel Bilgiler
          </span>
        </template>
        <a-form :model="form" :rules="rules" layout="vertical">
          <a-alert
              v-if="info"
              message="Uyarı"
              description="İlk defa işlem yapacağınız bir müşteri otomatik olarak size atanacaktır."
              type="error"
              show-icon
              style="margin-bottom: 10px"
          />
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-item label="Müşteri Adı">
                <a-input v-model:value="form.name"/>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Müşteri Temsilcisi">
                <a-input v-model:value="form.agent" readonly></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-item
                  :key="index"
                  v-for="(phone, index) in form.phones"
                  label="Müşteri Telefon"
              >
                <div class="infoArea">
                  <a-button
                      v-if="index > 0"
                      type="danger"
                      @click="removeNumber(index)"
                  >
                    <DeleteOutlined/>
                  </a-button>

                  <a-input
                      v-model:value="form.phones[index]"
                      addon-before="+90"
                      type="number"
                  />
                  <a-button
                      type="primary"
                      @click="addNumber"
                      v-if="index === 0"
                  >
                    <PlusOutlined/>
                  </a-button>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                  :key="index"
                  v-for="(mail, index) in form.mail"
                  label="Müşteri Mail Adresi"
              >
                <div class="infoArea">
                  <a-button
                      v-if="index > 0"
                      type="danger"
                      @click="removeMail(index)"
                  >
                    <DeleteOutlined/>
                  </a-button>
                  <a-input
                      v-model:value="form.mail[index]"
                      style="width: 100%"
                  />
                  <a-button v-if="index === 0" type="primary" @click="addMail">
                    <PlusOutlined/>
                  </a-button>
                </div>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-item label="Vergi Dairesi">
                <a-input v-model:value="form.taxName"/>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Vergi Numarası">
                <a-input type="number" v-model:value="form.taxNumber"></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-item label="Müşteri Sözleşme ve Belgeleri">
                <UploadBtn
                    :customer="customerInfo"
                    @fileListUpdate="form.files = $event"
                    :customerFileList="form.files"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item label="Müşteri Özel Not" name="description">
                <a-textarea
                    v-model:value="form.description"
                    :rows="4"
                    placeholder="müşteri hakkında özel notunuz"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2">
        <template #tab>
          <span>
            <ClusterOutlined/>
            Entegrasyon Bilgileri
          </span>
        </template>

        <IntegrationDetails @refreshTable="refreshData" :catalogs="catalogs" :customer="customerInfo"/>
      </a-tab-pane>
      <a-tab-pane key="3">
        <template #tab>
          <span>
            <LineChartOutlined/>
            İşlem Geçmişi
          </span>
        </template>

        <customer-logs :customer="customerInfo"/>
      </a-tab-pane>
    </a-tabs>

    <div class="drawerFooter">
      <a-button style="margin-right: 8px" @click="closeDrawer">Vazgeç</a-button>
      <a-button type="primary" :disabled="submitBtnDisabled" @click="onSubmit"
      >Kaydet
      </a-button>
    </div>
  </a-drawer>
</template>
<script lang="ts">
import {
  PlusOutlined,
  DeleteOutlined,
  UserOutlined,
  ClusterOutlined,
  LineChartOutlined
} from "@ant-design/icons-vue";
import {defineComponent, reactive, ref} from "vue";
import IntegrationDetails from "./IntegrationDetails.vue";
import UploadBtn from "../../../components/UploadBtn.vue";
import CustomerLogs from "../../../components/CustomerLogs.vue";

export default defineComponent({
  data() {
    return {
      info: false,
      customerInfo: null,
      catalogs: [],
    };
  },
  components: {
    PlusOutlined, DeleteOutlined, UserOutlined, ClusterOutlined, LineChartOutlined,
    IntegrationDetails, UploadBtn, CustomerLogs
  },
  methods: {
    closeDrawer() {
      this.$emit("visibleChanged", false);
    },
    onSubmit() {
      let catalogs = this.$store.getters.getCatalogs

      catalogs.forEach((v, k) => {
        this.form.catalogs.push(v)
      })

      if (catalogs.length > 0)
        this.form.addCatalog = true

      if (this.assigned) {
        this.$store.dispatch("callUpdateAssignedCustomerInformation", this.form);
      } else {
        this.$store.dispatch("callUpdateCustomerInformation", this.form);
      }

      this.refreshData();
    },
    refreshData() {
      /* store catalog'u temizler */
      this.$store.dispatch('flushCatalog')
      this.catalogs = []
      /* store catalog'u temizler */

      this.$store.dispatch("callCustomerInformation", this.customerInfo._id["$oid"]).then(data => {
        if (data)
          this.customerInfoUpdate();
        this.$emit('refreshCustomerTable', true)
      })
    },
    customerInfoUpdate() {
      let customer = this.$store.getters.getCustomerInformation;
      this.customerInfo = customer;
      this.form.cp_id = customer.cp_id;
      this.form.oid = customer._id["$oid"];
      this.form.name = customer.name;
      this.form.mail = customer.mail;
      this.form.phones = customer.phones;
      this.form.taxName = typeof customer.taxName !== "undefined" ? customer.taxName : "";
      this.form.taxNumber = typeof customer.taxNumber !== "undefined" ? customer.taxNumber : "";
      this.form.description = customer.description;
      this.form.files = typeof customer.files !== "undefined" ? customer.files : [];
      this.form.catalogs = typeof customer.catalogs !== "undefined" ? customer.catalogs : [];
      this.form.agent = typeof customer.agent !== "undefined" ? customer.agent.name : "";
      this.info = typeof customer.agent === "undefined";
      this.form.addCatalog = false;
    },
    visibleChange(val) {
      /* store catalog'u temizler */
      this.$store.dispatch('flushCatalog')
      this.catalogs = []
      /* store catalog'u temizler */

      if (val) {
        this.customerInfoUpdate();
      }
    },
    addNumber() {
      this.form.phones.push("");
    },
    removeNumber(index) {
      this.form.phones.splice(index, 1);
    },
    addMail() {
      this.form.mail.push("");
    },
    removeMail(index) {
      this.form.mail.splice(index, 1);
    }
  },
  computed: {
    submitBtnDisabled() {
      return !(this.form.phones[0] !== "" && this.form.taxName !== "" && this.form.taxNumber)
    }
  },
  props: ["drawerVisibility", "assigned"],
  setup() {
    const form = reactive({
      oid: "",
      cp_id: "",
      name: "",
      agent: "",
      mail: [""],
      phones: [""],
      description: "",
      taxName: "",
      taxNumber: "",
      catalogs: [],
      files: [],
      addCatalog: false
    });

    const rules = {
      oid: [{required: true}],
      cp_id: [{required: true}],
      name: [{required: true}],
      mail: [{required: true}],
      phones: [{required: true}],
      description: [{required: false}],
      catalogs: [{required: false}],
      files: [{required: false}],
      taxName: [{required: true}],
      taxNumber: [{required: true}],
      addCatalog: [{required: false}],
    };

    return {
      form,
      rules,
      activeKey: ref("1")
    };
  }
});
</script>

<style scoped>
.infoArea {
  display: flex;
  flex-direction: row-reverse;
}

.infoArea > button {
  margin-right: 5px;
}

.drawerFooter {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
</style>
