<template>
  <div class="reverse" v-if="typeof customer.logs !== 'undefined'">
    <a-alert class="mt-15" v-for="item in customer.logs"
             :message="item.title"
             :description="item.description + ' | ' + new Date(item.createdAt * 1000).getFullYear() + '/' + (new Date(item.createdAt * 1000).getMonth() + 1) + '/' + new Date(item.createdAt * 1000).getDate() + ' - ' + new Date(item.createdAt * 1000).getHours() + ':' + new Date(item.createdAt * 1000).getMinutes() + ':' + new Date(item.createdAt * 1000).getSeconds() "
             :type="item.type"
             show-icon
    />
  </div>

  <a-alert v-if="typeof customer.logs === 'undefined'"
           message="Henüz bir işlem yok"
           description="Bu müşteride henüz herhangi bir işlem yapılmamış."
           type="info"
           show-icon
  />
</template>

<script>
export default {
  name: "CustomerLogs",
  props: ["customer"]
}
</script>

<style scoped>
.mt-15 {
  margin-top: 15px;
}

.reverse {
  display: flex;
  flex-direction: column-reverse;
}
</style>